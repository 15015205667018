import gql from 'graphql-tag';
import { CartItemFields } from './common-cart-fields.queries';

export const CART_ITEMS_BY = gql`
	query CartItemsBy($filtersInput: CartFiltersInput!) {
		cartItemsBy(filtersInput: $filtersInput) {
			...CartItemFields
		}
	}
	${CartItemFields}
`;

export const ADD_PRODUCT_TO_CART = gql`
	mutation AddProductToCart($input: AddToCartInput!, $subItems: [AddToCartInput!]) {
		addProductToCart(input: $input, subItems: $subItems) {
			cart {
				id
				quantity
				cartTotals {
					subtotal
				}
				items {
					id
					variant {
						id
					}
					quantity
					price {
						unitPrice
					}
				}
			}
			newItemIds
		}
	}
`;
